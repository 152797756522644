import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />

    <h1>Contact Candeo Design</h1>

    <p>
      Office:
      <br />
      <strong>
        47 Pacific Drive
        <br />
        Torquay VIC 3228 Australia
      </strong>
    </p>

    <p>
      Phone:
      <br />
      <strong>
        <a href="tel:+61423080728" title="Click to call us">
          0423 080 728
        </a>
      </strong>
    </p>

    <p>
      Fax:
      <br />
      <strong>(03) 9701 8807</strong>
    </p>

    <p>
      Email:
      <br />
      <strong>
        <a href="mailto:info@candeodesign.com.au" title="Click to email us">
          info@candeodesign.com.au
        </a>
      </strong>
    </p>
  </Layout>
)
export default ContactPage
